import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';




const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
  
  
    {
    path: 'changepassword',
    loadChildren: () => import('./changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  
  
  
  {
    path: 'home',
    loadChildren: () =>import('./home/home.module').then( m => m.HomePageModule) 
  },
  
  
    {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then( m => m.SupplierPageModule)
  },
  
  
    
    {
    path: 'season',
    loadChildren: () => import('./season/season.module').then( m => m.SeasonPageModule)
  },
  
  
  
  {
    path: 'ordertype',
    loadChildren: () => import('./ordertype/ordertype.module').then( m => m.OrdertypePageModule)
  },
  
  
 {
    path: 'sampletype',
    loadChildren: () => import('./sampletype/sampletype.module').then( m => m.SampletypePageModule)
  },
  {
    path: 'mill',
    loadChildren: () => import('./mill/mill.module').then( m => m.millPageModule)
  },
  {
    path: 'item',
    loadChildren: () => import('./item/item.module').then( m => m.itemPageModule)
  },
   {
    path: 'currency',
    loadChildren: () => import('./currency/currency.module').then( m => m.CurrencyPageModule)
  },
   {
    path: 'color',
    loadChildren: () => import('./color/color.module').then( m => m.ColorPageModule)
  },
  
  
    
  {
    path: 'uom',
    loadChildren: () => import('./uom/uom.module').then( m => m.UomPageModule)
  },
  
  
  
  
  
    {
    path: 'poentrysample',
    loadChildren: () => import('./poentrysample/poentrysample.module').then( m => m.PoentrysamplePageModule)
  },
     
  
  {
    path: 'poentrybulk',
    loadChildren: () => import('./poentrybulk/poentrybulk.module').then( m => m.PoentrybulkPageModule)
  },
  
  
    {
    path: 'povalidationsample',
    loadChildren: () => import('./povalidationsample/povalidationsample.module').then( m => m.PovalidationsamplePageModule)
  },
   
  {
    path: 'povalidationbulk',
    loadChildren: () => import('./povalidationbulk/povalidationbulk.module').then( m => m.PovalidationbulkPageModule)
  },
  
    {
    path: 'poclosesample',
    loadChildren: () => import('./poclosesample/poclosesample.module').then( m => m.PoclosesamplePageModule)
  },
  {
    path: 'poclosebulk',
    loadChildren: () => import('./poclosebulk/poclosebulk.module').then( m => m.PoclosebulkPageModule)
  } ,

  
  
  
    {
    path: 'reportsample',
    loadChildren: () => import('./reportsample/reportsample.module').then( m => m.ReportsamplePageModule)
  },
  

  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  },
  
  
    
  
  {
    path: 'rolepermission',
    loadChildren: () => import('./rolepermission/rolepermission.module').then( m => m.RolepermissionPageModule)
  },

  {
    path: 'userpermission',
    loadChildren: () => import('./userpermission/userpermission.module').then( m => m.UserpermissionPageModule)
  },
  {
    path: 'component',
    loadChildren: () => import('./component/component.module').then( m => m.ComponentPageModule)
  },
  {
    path: 'componentfamily',
    loadChildren: () => import('./componentfamily/componentfamily.module').then( m => m.ComponentfamilyPageModule)
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendor/vendor.module').then( m => m.VendorPageModule)
  },
  {
    path: 'buyer',
    loadChildren: () => import('./buyer/buyer.module').then( m => m.BuyerPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./merchandiser/merchandiser.module').then( m => m.MerchandiserPageModule)
  },
  {
    path: 'role',
    loadChildren: () => import('./masterroles/masterroles.module').then( m => m.MasterrolesPageModule)
  }



];


@NgModule({
  imports: [
   // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
   
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
	
	
	
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
