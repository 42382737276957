import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

import { AuthService } from '../app/providers/auth.service';


import { MenuController } from '@ionic/angular';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'], 	
	  preserveWhitespaces: true
})
export class AppComponent {


  menulist: any = [];


  titlearr = ['Masters', "Transactions"]




  subscription: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,

    public authservice: AuthService,
    private router: Router,

    public menuCtrl: MenuController

  ) {

    this.initializeApp();

    this.subscription = this.authservice.getMessage().subscribe(text => {

      this.menulist = text.created;

      this.menuCtrl.enable(true);

    })


    setTimeout(() => {

      if (this.menulist.length == 0) {

        let itm = localStorage.getItem("yarnSMTOOL_menustorage");

        if (itm) this.menulist = JSON.parse(itm);

        this.menuCtrl.enable(true);

      }
    }, 2000)

  }

  navigatepages(page) {
  
    if (page != 'undefined') { 
   //   alert(page);
      this.router.navigate([page], {
        replaceUrl: true
      });

    }
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
